import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Col, Row } from "reactstrap"
import { Layout, ContactForm } from "../../components"

import {
  Banner,
  BannerStyle,
  ContainerCustom,
  PaddingWrapper,
} from "@igloonet-web/shared-ui"

import PersonMichelin from "../../images/team/mini/michelin.jpg"
import PersonAdam from "../../images/team/mini/adam.jpg"
import PersonWicki from "../../images/team/mini/wicki.jpg"
import ContactDetail from "@igloonet-web/shared-ui/components/contact-detail"

const TextBoxImageTopWrapper = styled.div`
  padding: 0 0 3rem;
  background: ${(props) => props.theme.color.gray200};

  img {
    max-width: 150px;
    border-radius: 4px;
  }
`

const TextBoxKontakt = (props) => (
  <Col
    md={6}
    lg={3}
    className="d-flex flex-column align-items-center mt-5 px-1"
  >
    <img src={props.imageHref} alt={""} loading="lazy" />
    <h3 className="mt-4">{props.city}</h3>
    <div className="mb-2">
      <div>{props.name}</div>
      <div>{props.address}</div>
    </div>
    <div className="mt-auto">
      <div>
        <a href={"tel:" + props.tel}>{props.tel}</a>
      </div>
      <div>
        <a href={"mailto:" + props.email}>{props.email}</a>
      </div>
    </div>
  </Col>
)

const Kontakt: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Kontakt - igloonet | igloonet</title>
        <meta
          name="description"
          content="Sídlíme v Brně-Řečkovicích na Karásku 5. Další kontaktní informace najdete na stránce."
        />
      </Helmet>
      <Banner
        style={BannerStyle.Reference}
        orangeHeading="Kontakt"
        blackHeading={`Stavte se k nám<br/>na kávu`}
      />

      <TextBoxImageTopWrapper>
        <ContainerCustom className="text-center">
          <Row className="justify-content-center">
            <TextBoxKontakt
              imageHref={PersonMichelin}
              city="Brno"
              name="Michal Polášek"
              tel="+420 739 450 400"
              email="info@igloonet.cz"
            />

            <TextBoxKontakt
              imageHref={PersonAdam}
              city="Praha"
              name="Adam Šilhan"
              tel="+420 739 634 302"
              email="adam@igloonet.cz"
            />

            <TextBoxKontakt
              imageHref={PersonWicki}
              city="Hosting"
              name="Martin Taraba"
              tel="+420 777 523 225"
              email="podpora@igloonet.cz"
            />
          </Row>
        </ContainerCustom>
      </TextBoxImageTopWrapper>

      <ContactDetail />

      <PaddingWrapper>
        <ContactForm contact="adam" />
      </PaddingWrapper>
    </Layout>
  )
}

export default Kontakt
