import React from "react"
import { Row, Col, Container } from "reactstrap"
import PaddingWrapper from "./padding-wrapper"
import {
  BlockImageTextHalf,
  ContainerBackground,
  ContainerBackgroundStyle,
  TitleH2,
} from ".."
import { Link } from "gatsby"
import Map from "../../igloonet/src/images/contact/map.svg"
import Car from "../../igloonet/src/images/contact/car.svg"
import Bus from "../../igloonet/src/images/contact/bus.svg"

const ContactDetail: React.FC = () => {
  const addressLink =
    "https://www.google.com/maps/place/Court+of+Moravia/@49.2025152,16.6106478,18z/data=!4m6!3m5!1s0x4712945c46c7118f:0xb3c1e1bba04c0bf2!8m2!3d49.202517!4d16.6115839!16s%2Fg%2F11cmtxty93?entry=ttu"
  return (
    <>
      <PaddingWrapper biggerPaddingTop>
        <Container className="d-flex flex-column align-items-center">
          <TitleH2>Chcete nás vidět osobně?</TitleH2>
          <p className="text-center">
            Pracujeme většinou vzdáleně, pokud si ale{" "}
            <strong>domluvíme schůzku</strong>,<br /> můžeme se potkat v naší
            kanceláři
          </p>
          <p className="d-flex">
            <a
              href={addressLink}
              target="_blank"
              className="text-center"
              rel="noreferrer"
            >
              Court of Moravia,
              <br />
              Bartošova 3, 602 00 Brno&#8209;střed&#8209;Černá Pole
            </a>
          </p>
        </Container>
        <BlockImageTextHalf
          image={Map}
          alt="Mapa sídla"
          right
          imgLink={addressLink}
        >
          <h3 className="pb-3">Jak se k nám dostanete</h3>
          {/* TODO OPRAVIT TEXTY */}
          <div className="d-inline-flex">
            <img
              src={Bus}
              alt="ikona-mhd"
              width="32"
              height="32"
              className="pr-2"
            />
            <h4>MHD</h4>
          </div>
          <p className="text-left">
            Můžete využít zastávku MHD <strong>Náměstí 28. října</strong>. Sveze
            vás na ni šalina č. <strong>9</strong> a <strong>5</strong>,
            případně trolejbus č. <strong>67</strong>.
          </p>
          <div className="d-inline-flex">
            <img
              src={Car}
              alt="ikona-autobus"
              width="32"
              height="32"
              className="pr-2"
            />
            <h4>Autem</h4>
          </div>
          <p className="text-left">
            Jestli se za námi chystáte autem, na celé ulici je modrá zóna B,
            takže pohodlně zaparkujete většinou přímo před kanceláří. Parkovací
            automaty jsou na rožku, případně můžete{" "}
            <Link to="https://ke-utc.appspot.com/static/select.html?label=BRNO">
              platit online
            </Link>
            .
          </p>
        </BlockImageTextHalf>
      </PaddingWrapper>
      <ContainerBackground style={ContainerBackgroundStyle.TertiaryBG}>
        <Container>
          <Row>
            <Col>
              <h4>Sídlo firmy</h4>
            </Col>
            <Col>
              <section>
                <p>
                  <strong>igloonet, s.r.o.</strong>
                </p>
                <p>Karásek 5, 621 00, Brno</p>
                <p>IČ: 27713482</p>
                <p>DIČ: CZ27713482</p>
              </section>
            </Col>
            <Col>
              <section>
                <p>zapsaná v OR vedeném KS v Brně, oddíl C, vložka 53913</p>
                <div>
                  <Link to="/kontakt/bankovni-spojeni">Bankovní spojení </Link>{" "}
                  | <Link to="/payment-info">Payment Info</Link>
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </ContainerBackground>
    </>
  )
}

export default ContactDetail
